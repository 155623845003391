
import { User } from "@/store/user/types";
import { Program } from "@/types";
import { Component, Vue } from "vue-property-decorator";
import { ROOT_ACTIONS } from "@/store/actions";
import { USER_GETTERS } from "@/store/user/getters";
import { collection, documentId, getDocs, query, where } from "firebase/firestore";
import { firestore } from "@/firebase";

@Component({
  components: {
    StateTypeSpan: () => import("@/components/typography/StateTypeSpan.vue"),
  },
})
export default class Home extends Vue {
  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  programs = [] as Program[];

  participants: Record<string, User> = {};

  loading = true;
  percentLoaded = 0;

  async mounted() {
    try {
      this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: 'Home', target: '/' }])
      const program = await getDocs(query(collection(firestore, 'programs'), where('coach', '==', this.user.id)))
      const users = await getDocs(query(collection(firestore, 'users')))

      users.forEach((doc) => {
        this.participants[doc.id] = {
          ...doc.data() as User,
          id: doc.id,
        }
      })

      this.programs = program.docs.map((doc) => {
        return {
          ...doc.data() as Program,
          id: doc.id,
        }
      })
    } catch (error) {
      console.error(error)
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: 'Error'
      })
    } finally {
      this.loading = false;
    }

  }
}
